.work {
  padding: 100px 20px 20px;
  font-family: Arial, sans-serif;
}

.work h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.video-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.video-section {
  flex: 1;
  margin-right: 20px;
  text-align: center;
}

.video-section:last-child {
  margin-right: 0;
}

.video-wrapper {
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
