.contact {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 100px 20px 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}

.contact p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
}

.contact a {
  color: #007bff;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}
