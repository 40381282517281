/* General styles */
html, body {
  height: 100%;
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

nav {
  background-color: #000;
  padding: 15px 0;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  margin-right: auto; /* Ensures the logo stays on the left */
}

.logo img {
  height: 50px; /* Adjust the height as needed */
  filter: invert(1) brightness(2);
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-icon span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px auto;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  flex-grow: 1;
}

nav ul li {
  margin: 0 20px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #61dafb;
}

main {
  flex: 1;
  padding: 0px 20px 20px 100px; /* Ensure there's enough space below the fixed nav */
  margin-top: 60px; /* Account for the height of the fixed nav */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  line-height: 1.6;
}

footer {
  text-align: center;
  font-size: 0.9em;
  color: #666;
  padding: 10px 0;
  background-color: #f1f1f1;
}

footer p {
  margin: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  nav ul {
    display: none;
  }

  nav ul.active {
    display: block;
    background-color: #000;
    padding: 0;
    position: absolute;
    top: 60px;
    width: 100%;
  }

  nav ul li {
    display: block;
    margin: 10px 0;
  }

  .nav-container {
    flex-direction: column;
    align-items: stretch;
  }

  main {
    padding: 80px 10px 10px; /* Adjust padding for mobile view */
  }

  h1 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
  }
}
