.about {
  padding: 100px 20px 20px;
  font-family: Arial, sans-serif;
}

.about-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.text-content {
  flex: 3;
  order: 2; /* Move text content below the image on smaller screens */
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Add some space between the image and text on smaller screens */
  order: 1; /* Move image to the top on smaller screens */
}

.profile-image {
  width: 200px; /* Adjust the size as needed */
  height: 200px; /* Adjust the size as needed */
  border-radius: 50%;
  object-fit: cover;
}

.about h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.about h2 {
  font-size: 1.5em;
  margin-top: 20px;
}

.credits {
  display: flex;
  flex-direction: column;
}

.broadcast-credits, .documentary-credits {
  margin-bottom: 20px;
}

.broadcast-credits ul, .documentary-credits ul {
  list-style-type: none;
  padding: 0;
}

.broadcast-credits li, .documentary-credits li {
  margin: 5px 0;
}

/* Media Queries for responsive design */
@media (min-width: 768px) {
  .about-content {
    flex-direction: row;
  }

  .text-content {
    order: 1; /* Move text content to the right on larger screens */
  }

  .image-container {
    order: 2; /* Move image to the left on larger screens */
    margin-bottom: 0;
  }

  .credits {
    flex-direction: row;
    justify-content: space-between;
  }

  .broadcast-credits, .documentary-credits {
    margin-bottom: 0;
    flex: 1;
    margin-right: 20px;
  }
}
